.background {
  --background: slateblue;
}

.fullsize {
  height: 100%;
  width: 100%;
}

.text-white {
  color: white;
}

.padding-medium {
  padding: 10px;
}
.padding-small {
  padding: 5px;
}
.padding-x-small {
  padding: 3px;
}
.padding-none {
  padding: 0px;
}
